.product-header {
    width: 100%;
    text-align: center;
    color: #feffff;
}

.product-name {
    width: 100%;
    text-align: center;
    padding: 0;
}

.product-name a {
    padding: 0;
    color: #feffff;
    font-style: italic;
    font-weight: 600;
    font-size: 2.5rem;
}

.product-name h1 {
    text-decoration: underline;
}

.pricingTable {
    text-align: center;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
}

.pricingTable .pricingTable-header {
    color: #feffff;
    background: #1f83a7;
}

.pricingTable .heading {
    display: block;
    padding-top: 25px;
}

.pricingTable .heading:after {
    content: "";
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    display: inline-block;
    width: 85%;
}

.pricingTable .heading>h3 {
    margin: 0;
    text-transform: capitalize;
    font-size: 38px;
    font-weight: 700;
    color: #fff;
}

.pricingTable .heading>span {
    text-transform: capitalize;
    font-size: 13px;
    margin-top: 5px;
    display: block;
}

.pricingTable .price-value {
    display: block;
    font-size: 25px;
}

.pricingTable .price-desc {
    display: block;
    padding-bottom: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.pricingTable-header>.price-value>.month {
    font-size: 14px;
    display: inline-block;
    text-transform: capitalize;
}

.pricingTable .price-value>span {
    display: block;
    font-size: 14px;
    line-height: 20px;
}

.pricingTable .pricingContent {
    text-transform: capitalize;
    background: #ffffff;
    color: #333333;
}

.pricingTable .pricingContent>i {
    font-size: 60px;
    margin-top: 20px;
    color: #0caa7d;
}

.pricingTable .pricingContent ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    text-align: left;
}

.pricingTable .pricingContent ul li {
    padding: 12px 0;
    border-bottom: 1px solid #000;
    border-top: 1px solid #333;
    width: 85%;
    margin: 0 auto;
}

.pricingTable .pricingContent ul li:first-child {
    border-top: 0px none;
}

.pricingTable .pricingContent ul li:last-child {
    border-bottom: 0px none;
}

.pricingTable .pricingContent ul li:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    font-weight: 900;
    margin-right: 10px;
    transition: all 0.5s ease 0s;
}

.pricingTable .pricingContent ul li:hover:before {
    margin-right: 20px;
}

.pricingTable .pricingTable-sign-up {
    padding: 20px 0;
    background: #ffffff;
    color: #fff;
    text-transform: capitalize;
}

.pricingTable .pricingTable-sign-up>span {
    margin-top: 10px;
    display: block;
}

.pricingTable .btn-block { 
    width: 70%;  
    margin: 0 auto;
    background: #fa0909;
    text-transform: capitalize;
    border: 0px none;
    font-size: 17px;
    transition: all 0.5s ease 0s;
    padding: 15px 55px;
    outline: none;
    border: none;
    border-radius: 30px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
}

.btn-block:hover {
    background-color: #cc0404;
}

.pricingTable a {
    color: #fff;
}

.pricingTable .btn-block:hover {
    border-radius: 35px;
}

.pricingTable .btn-block:before {
    content: "\f07a";
    font-family: 'FontAwesome';
    font-weight: 900;
    margin-right: 10px;
}

.pricingTable.pink .pricingTable-header {
    background: #1f83a7;
}

.pricingTable.orange .pricingTable-header {
    background: #1f83a7;
}

.pricingTable.green .pricingTable-header {
    background: #1f83a7;
}

@media screen and (max-width: 990px) {
    .pricingTable {
        margin-bottom: 20px;
    }
}